<template>
  <div v-for="(chatroom, index) of chatrooms" :key="index" class="wrapper" :class="{ hide: index >= 4 }">
    <div v-if="!isEmpty(chatroom)" class="d-flex chat-rooms px-2">
      <ion-card class="chat-room-card mx-0 py-3 px-3 clickable-item">
        <div class="d-flex justify-content-end" style="margin-top: -4px">
          <ion-badge class="" color="danger">
            <span v-if="get(unread, `${chatroom.id}.unread_count`)">
              {{ get(unread, `${chatroom.id}.unread_count`) || 0 }}</span
            >
          </ion-badge>
        </div>
        <router-link :to="{ name: routerName(chatroom), params: { id: chatroom.id } }">
          <div class="d-flex">
            <img loading="lazy" v-image class="img" :src="resizeUpload(chatroom.banner_img_url, '80x80')" />

            <div class="mx-2 flex-grow-1 text-black">
              <div class="d-flex">
                <div class="title text-black d-flex align-items-center justify-content-start ml-1">
                  {{ chatroom.title }}
                </div>
                <div v-if="get(muted, `${chatroom.id}.is_muted`)" class="mx-1" fill="none" style="font-size: 20px">
                  <ion-icon :icon="volumeMuteOutline" />
                </div>
              </div>
              <div
                class="d-flex"
                :class="{
                  'flex-column small-btn': isMobSmallScreen,
                  'set-margin': get(muted, `${chatroom.id}.is_muted`),
                }"
              >
                <ion-button
                  v-if="get(status, `${chatroom.id}.status`)"
                  class="chatroom-btn"
                  style="--background: #00b4c5"
                >
                  <div class="d-flex">
                    <ion-icon class="icon" :icon="checkmarkCircleOutline" />
                    <div class="text mx-1">Joined</div>
                  </div>
                </ion-button>
                <ion-button
                  v-if="chatroom.privacy === 'private' || get(chatroom, 'world.privacy') === 'O'"
                  :class="chatroom.privacy === 'private' ? 'private-chatroom-button' : 'chatroom-btn'"
                >
                  <div class="d-flex">
                    <i v-if="chatroom.privacy === 'private'" class="icon ti-lock" />
                    <ion-icon v-else class="icon" :icon="peopleOutline" />
                    <div class="text mx-1">{{ chatroom.privacy }} chatroom</div>
                  </div>
                </ion-button>
              </div>
              <div v-if="!isMobSmallScreen" class="description mx-1">
                {{ truncateText(stripHtmlTags(chatroom.description), 120) }}
              </div>
              <div v-if="!isMobSmallScreen" class="d-flex mt-1 mx-1">
                <div class="d-flex">
                  <strong> <ion-icon class="member-icon" :icon="peopleOutline" /></strong>
                  <div v-if="get(memberCount, `${chatroom.id}.member_count`)" class="ml-1">
                    {{ get(memberCount, `${chatroom.id}.member_count`) || 0 }}
                  </div>
                </div>

                <div v-if="get(lastmsg, `${chatroom.id}.last_msg`)" class="d-flex pl-3">
                  <div><strong>Last Message: </strong></div>
                  <div class="mx-1">
                    {{ formatTimeAgo(get(lastmsg, `${chatroom.id}.last_msg`)) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>

        <div v-if="isMobSmallScreen" class="mt-2">
          <div v-html="sanitizeHtml(chatroom.description)" class="description"></div>
          <div class="d-flex mt-1">
            <div class="d-flex">
              <strong> <ion-icon class="member-icon" :icon="peopleOutline" /></strong>

              <div class="ml-1">{{ get(memberCount, `${chatroom.id}.member_count`) || 0 }}</div>
            </div>
            <div v-if="get(lastmsg, `${chatroom.id}.last_msg`)" class="d-flex pl-3">
              <div><strong>Last Message: </strong></div>
              <div class="mx-1">
                {{ formatTimeAgo(get(lastmsg, `${chatroom.id}.last_msg`)) }}
              </div>
            </div>
          </div>
        </div>
      </ion-card>
    </div>
    <div v-else class="no-data">No Chatrooms here yet</div>
  </div>
  <div class="d-flex justify-content-end mt-3">
    <ion-button class="chatroom-btn" color="primary" @click="seeallchat">See all Chatrooms</ion-button>
  </div>
</template>

<script lang="ts" setup>
import { checkmarkCircleOutline, peopleOutline, volumeMuteOutline } from 'ionicons/icons';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import { sanitizeHtml } from '@/shared/utils/html';
import { stripHtmlTags } from '@/shared/utils/string';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';

const props = defineProps({
  chatrooms: {
    type: Object,
    default: {},
  },
  status: {
    type: Object,
  },
  unread: {
    type: Object,
  },
  memberCount: {
    type: Object,
  },
  unreadChat: {
    type: Number,
    default: 0,
  },
  lastmsg: {
    type: Object,
  },
  id: {
    type: Number,
  },
  muted: {
    type: Object,
  },
});

const chatrooms = toRef(props, 'chatrooms');
const status = toRef(props, 'status');

const emits = defineEmits(['changeTab']);

const seeallchat = () => {
  emits('changeTab', { value: 'chatroom' });
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});

const routerName = (chatroom: any) => {
  return chatroom.privacy === 'private' && !get(status.value, `${chatroom.id}.status`)
    ? 'chatroom-details'
    : 'social-space-chatroom';
};
const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(async () => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.set-margin
  margin-top: -4px
.chatroom-btn
  --border-radius: 15px
  height: 20px
.hide
  display: none
.private-chatroom-button
  --background: #341e7a
  --border-radius: 15px
  height: 20px

.text
  font-size: 12px

.social-space-chatrooms
  overflow-x: auto
.section-title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.title
  font-size: 20px
  font-weight: bold
  margin-top: -5px

.description
  font-size: 14px

.member-icon
  font-size: 16px
  font-weight: bold

.icon
  font-size: 12px
  font-weight: bold

.chat-room-card
  overflow: unset

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .img
    width: 65px !important
    height: 65px !important
    border-radius: 35px
    border: solid gray 0.1px
    object-fit: cover
    @media(max-width: 500px)
      width: 60px !important
      height: 60px !important
      border-radius: 30px
      border: solid gray 0.1px
      object-fit: cover
      margin-left: -8px

.chat-rooms
  overflow-x: auto
  overflow: unset
.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none

@media(max-width: 318px)
  .small-btn
    width: calc(100% - 29px) !important
    margin-left: -8px !important
  .title
    width: calc(100% - 42px)
    margin-left: -6px !important
</style>
