<template>
  <div v-if="socialspace">
    <social-space-actions v-if="hasSections" class="my-3" :sections-collapsed="sectionsCollapsed" />
    <social-space-section :socialspace="socialspace" :status="status" @changeTab="changeTab" @added="addedchar" />
  </div>
</template>

<script lang="ts" setup>
import SocialSpaceSection from './SocialSpaceSection.vue';
import SocialSpaceActions from './SocialSpaceActions.vue';

const router = useRouter();
const route = useRoute();
const emits = defineEmits(['changeTab', 'added']);

const props = defineProps({
  sectionsCollapsed: {
    default: -1,
  },
  socialspace: {
    type: Object,
  },
  hasSections: {
    type: Boolean,
  },
  status: {
    type: Object,
  },
});

const addedchar = () => {
  emits('added');
};
const changeTab = (value: any) => {
  emits('changeTab', value);
};
const sectionsCollapsed = toRef(props, 'sectionsCollapsed');
const socialspace = toRef(props, 'socialspace');
const hasSections = toRef(props, 'hasSections');
</script>
<style lang="sass" scoped>
.loading-content
  width: 100%
.social-space-page
  overflow: hidden
  padding: 24px 0 !important
  // margin-top: -40px !important
  min-height: 628px
.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease

.v-enter-from,
.v-leave-to
  opacity: 0
</style>
