<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent px-3"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="bg-dark" :value="index">
      <div class="d-flex">
        <i class="d-flex" style="font-size: 24px; color: white; font-weight: normal" :class="tab.icon" />
        <ion-label class="mx-1 mt-1" style="color: white">{{ tab.name }}</ion-label>
      </div>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
});
const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const tabs = toRef(props, 'tabs');

const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.segment-button-checked::part(native)
  background: var(--ion-color-primary)
  border-top-left-radius: 11px
  border-top-right-radius: 12px

.segment-button::part(native)
  background: var(--ion-color-primary)
  border-top-left-radius: 12px
  border-top-right-radius: 12px

ion-segment-button::part(native)
  background: #807d7d
  border-top-left-radius: 12px
  border-top-right-radius: 12px
</style>
