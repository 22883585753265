<template>
  <div class="story-world-header-loading position-relative">
    <div class="cover">
      <ion-skeleton-text animated class="image m-0" />
    </div>
    <div class="rest position-relative">
      <div class="edit-world d-flex justify-content-end position-absolute">
        <ion-skeleton-text animated class="loading" />
        <ion-skeleton-text animated class="loading" />
      </div>
      <div class="name mx-auto">
        <ion-skeleton-text animated class="loading" />
      </div>
      <div class="description mx-auto">
        <ion-skeleton-text animated class="loading" />
      </div>
      <div class="quote" />
      <div class="tags mx-auto d-flex justify-content-center">
        <ion-skeleton-text v-for="(item, index) of [0, 1, 2]" :key="index" animated class="tag" />
      </div>
      <div class="actions mx-auto d-flex">
        <ion-skeleton-text v-for="(item, index) of [0, 1, 2]" :key="index" animated class="loading" />
      </div>
      <div class="owner">
        <ion-skeleton-text animated class="loading" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="sass" scoped>
.story-world-header-loading
  color: #FFF
  border-radius: 20px
  overflow: hidden
  .profile-picture
    width: 191px
    height: 191px
    border-radius: 100px
    overflow: hidden
    border: 10px solid #FFF
    margin: -95px auto 0 auto
    background-color: #CCC
    z-index: 1
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .cover
    height: 428px
    overflow: hidden
    border-radius: 20px 20px 0 0
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .rest
    text-align: center
    background: #E6E6E6
    margin-top: -95px
    padding: 30px 24px 12px 24px
  .edit-world
    margin-top: -34px
    width: calc(100% - 24px)
    .loading
      background-color: #CCC
      text-transform: unset
      font-size: 14px
      font-weight: 500
      width: 100px
      height: 20px
      margin-right: 24px
      border-radius: 6px
      margin-top: 10px
  .owner
    margin-top: 24px
    text-align: start
    width: 200px
    height: 20px
    background-color: #CCC
    border-radius: 5px
  .actions
    margin-top: 24px
    width: 85%
    .loading
      height: 40px
      margin: 0 10px
      flex-grow: 1
      width: 100%
      background-color: #CCC
      border-radius: 8px
  .quote
    margin-top: 24px
    font-weight: 700
    font-size: 16px
    font-style: italic
  .description
    margin-top: 15px
    font-size: 20px
    font-weight: 500
    border-radius: 8px
    height: 24px
    width: 450px
    background-color: #CCC
  .name
    margin-top: 24px
    font-size: 24px
    border-radius: 8px
    height: 24px
    width: 250px
    background-color: #CCC
    font-weight: 700
  .tags
    margin-top: 24px
    width: 80%
    .tag
      background-color: #CCC
      border-radius: 5px
      height: 20px
      width: 60px
      margin: 0 2.5px
      font-size: 10px
      padding: 0 8px
</style>
