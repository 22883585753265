<template>
  <div>
    <div class="content mt-4">
      <div class="chatroom-content px-2 py-3">
        <div class="title">Chatrooms</div>
        <div class="d-flex">
          <div class="ml-2 w-100 mt-5">
            <SearchInput
              v-model="search.text"
              :search-icon="true"
              :shortcut-icon="false"
              :clear-icon="true"
              :hide-shortcut-icon-on-blur="true"
              :clear-on-esc="false"
              :blur-on-esc="true"
              :select-on-focus="false"
              :shortcut-listener-enabled="true"
              placeholder="Aa"
              @click.stop
            />
          </div>
        </div>
        <div class="mt-4">
          <ChatroomCard
            v-for="(item, index) of rooms"
            :key="index"
            :chatroom="item"
            :lastmsg="get(chatroomLastMsg, `${item.id}.last_msg`)"
            :muted="get(mutedStatus, `${item.id}.is_muted`)"
            :member-count="get(chatroomMemberCounts, `${item.id}.member_count`) || 0"
            :unreadchat="get(chatUnreadCounts, `${item.id}.unread_count`) || 0"
            :status="get(chatroomStatus, `${item.id}.status`) || false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import {
  getChatroomLastMsg,
  getChatroomMemberCounts,
  getChatroomStatus,
  getchatUnreadCounts,
  getUnmuteAndMuteNotificationStatus,
} from '@/shared/actions/socialSpaceChatroom';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';

const props = defineProps({
  rooms: {
    type: Array,
    default: [],
  },
  id: {
    type: String,
    default: '',
  },
});

const rooms = toRef(props, 'rooms') as any;
const chatroomMemberCounts = ref({});
const mutedStatus = ref({});
const chatUnreadCounts = ref({});
const id = toRef(props, 'id');
const chatroomStatus = ref({});
const chatroomLastMsg = ref({});

const search = ref({
  text: '',
});
const fetchChatroomStatus = async () => {
  const resp = await getChatroomStatus(id.value, map(rooms.value, 'id') as string[]);
  chatroomStatus.value = keyBy(resp, 'room');
};
const fetchChatroomLastMsg = async () => {
  const resp = await getChatroomLastMsg(id.value, map(rooms.value, 'id') as string[]);
  chatroomLastMsg.value = keyBy(resp, 'room');
};
const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(rooms.value, 'id') as string[]);
  chatUnreadCounts.value = keyBy(resp, 'room');
};
const fetchChatroomMemberCounts = async () => {
  const resp = await getChatroomMemberCounts(id.value, map(rooms.value, 'id') as string[]);
  chatroomMemberCounts.value = keyBy(resp, 'room');
};
const fetchMutedAndUmuteStatus = async () => {
  const resp = await getUnmuteAndMuteNotificationStatus(map(rooms.value, 'id') as string[]);
  mutedStatus.value = keyBy(resp, 'room');
};
onMounted(() => {
  if (rooms.value && id.value) {
    fetchChatroomStatus();
    fetchChatroomMemberCounts();
    fetchChatroomLastMsg();
    fetchchatUnreadCounts();
    fetchMutedAndUmuteStatus();
  }
});
</script>

<style lang="sass" scoped>
.dark .content .chatroom-content
  background: #17074C !important

.title
  font-size: 20px
  font-weight: bold
.content
  max-width: 100%
  .chatroom-content
    font-size: 14px
    background: #e6e6e6
    border-radius: 10px
</style>
